import styled from "styled-components";
import { colours } from "../../assets/theme";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  background-color: ${colours.ui.white};
  z-index: 10000000;

  @media screen and (max-width: 700px) {
    svg {
      max-height: 80px;
    }
  }
`;
