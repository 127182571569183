export default [
  "M587.606 2H611.52L641.729 86.1276H617.423L587.606 2Z",
  "M520.208 176H495.485L525.693 91.8724H550.025L520.208 176Z",
  "M552.053 86.1276H527.747H527.739L557.947 2H581.861L552.053 86.1276Z",
  "M489.73 176H466.365V91.8724H489.73V176Z",
  "M489.73 86.1276H466.365V2H489.73V86.1276Z",
  "M408.899 176H384.568L409.578 91.8724H433.91L408.899 176Z",
  "M435.617 86.1276H411.285H411.276L436.287 2H460.619L435.617 86.1276Z",
  "M354.499 176L329.488 91.8724H353.82L378.831 176H354.499Z",
  "M302.77 2H327.102L352.113 86.1276H327.781L302.77 2Z",
  "M297.034 176H273.668V91.8724H297.034V176Z",
  "M297.034 86.1276H273.668V2H297.034V86.1276Z",
  "M225.109 176H200.646L221.33 91.8724H245.81L225.109 176Z",
  "M247.221 86.1276H222.741H222.732L243.416 2H267.922L247.221 86.1276Z",
  "M170.917 176L154.193 91.8724H154.202H178.212L194.9 176H170.917Z",
  "M136.33 2H160.374L177.071 86.1276H153.053L136.33 2Z",
  "M96.0056 176H72.0306L88.7191 91.8724H112.729L96.0056 176Z",
  "M113.869 86.1276H89.8508L106.539 2H130.584L113.869 86.1276Z",
  "M-1 2H23.506L44.1817 86.1276H19.7017L-1 2Z",
  "M41.8225 176L21.1207 91.8724H45.6007L66.285 176H41.8225Z",
  "M674 176H649.268L619.451 91.8724H643.783L673.991 176H674Z",
];
