export const on = (obj, ...args) => {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...args);
  }
};

export const off = (obj, ...args) => {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...args);
  }
};

export const isBrowser = typeof window !== "undefined";

export const isNavigator = typeof navigator !== "undefined";

export const wrap = (min, max, v) => {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

/**
 * Gets a random element from an array.
 *
 * @private
 * @param {Array} array The array to sample.
 * @returns {*} Returns the random element.
 */
export const sample = (array) => {
  const { length } = array;
  const lower = 0;
  const upper = length - 1;
  const index = lower + Math.floor(Math.random() * (upper - lower + 1));
  return length ? array[index] : undefined;
};

/**
 * removes an item from an array by index.
 *
 * @private
 * @param {Array} array The array to remove from.
 * @param {Number} index The index of the item to remove.
 * @returns {Array} New array without item.
 */
export const deleteItemByIndex = (array, index) => {
  if (index >= 0 && index < array.length) {
    return [...array.slice(0, index), ...array.slice(index + 1)];
  }
  return array;
};
