import textStylesConfig from "./textStyles";

export const colours = {
  ui: {
    black: "#000000",
    white: "#FFFFFF",
    grey434240: "#434240",
    greyDark: "#D8D4D3",
    greyLight: "#ECE9E9",
    offWhite: "#F8F7F7",
  },
  highlight: {
    slate: "#818B88",
    leaf: "#60D29A",
    deepGreen: "#4B8D86",
    earth: "#80683E",
    purple: "#3B3746",
    blue: "#271089",
    purpleLight: "#6e3f86",
    red: "#d81900",
  },
};

export const textSizes = {
  xs: 10,
  sm: 14,
  md: 22,
  lg: 42,
  xl: 72,
  hg: 102,
};

export const textStyles = textStylesConfig;

export const screenSizes = {
  xs: 670,
  sm: 800,
  md: 1000,
  lg: 1500,
  xl: 1700,
};

export const getDefaultTheme = (screenWidth, inverted, theme) => ({
  background:
    theme?.background || (inverted ? colours.ui.black : colours.ui.offWhite),
  foreground:
    theme?.foreground || (inverted ? colours.ui.offWhite : colours.ui.black),
  white: colours.ui.white,
  greyLight: colours.ui.greyLight,
  greyDark: colours.ui.greyDark,
  grey434240: colours.ui.grey434240,
  highlight: theme?.highlight || colours.highlight.deepGreen,
  textStyles:
    screenWidth <= screenSizes.sm ? textStyles.mobile : textStyles.desktop,
});

export default {
  colours,
  textSizes,
  textStyles,
  getDefaultTheme,
};
