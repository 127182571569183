import React from "react";
import { Container } from "./styles";
import LoaderLogo from "../Logo/Loader";

const Loader = ({ onComplete, children }) => (
  <>
    <Container>
      <LoaderLogo height={135} onComplete={onComplete} />
    </Container>
    {children}
  </>
);

export default Loader;
