exports.components = {
  "component---src-page-components-archive-record-index-js": () => import("./../../../src/page-components/ArchiveRecord/index.js" /* webpackChunkName: "component---src-page-components-archive-record-index-js" */),
  "component---src-page-components-collabortator-index-js": () => import("./../../../src/page-components/Collabortator/index.js" /* webpackChunkName: "component---src-page-components-collabortator-index-js" */),
  "component---src-page-components-collection-index-js": () => import("./../../../src/page-components/Collection/index.js" /* webpackChunkName: "component---src-page-components-collection-index-js" */),
  "component---src-page-components-event-index-js": () => import("./../../../src/page-components/Event/index.js" /* webpackChunkName: "component---src-page-components-event-index-js" */),
  "component---src-page-components-news-item-index-js": () => import("./../../../src/page-components/NewsItem/index.js" /* webpackChunkName: "component---src-page-components-news-item-index-js" */),
  "component---src-page-components-portfolio-index-js": () => import("./../../../src/page-components/Portfolio/index.js" /* webpackChunkName: "component---src-page-components-portfolio-index-js" */),
  "component---src-page-components-portfolio-item-index-js": () => import("./../../../src/page-components/PortfolioItem/index.js" /* webpackChunkName: "component---src-page-components-portfolio-item-index-js" */),
  "component---src-page-components-theme-index-js": () => import("./../../../src/page-components/Theme/index.js" /* webpackChunkName: "component---src-page-components-theme-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-commission-js": () => import("./../../../src/pages/commission.js" /* webpackChunkName: "component---src-pages-commission-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-current-exhibitions-js": () => import("./../../../src/pages/current-exhibitions.js" /* webpackChunkName: "component---src-pages-current-exhibitions-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-programmes-js": () => import("./../../../src/pages/programmes.js" /* webpackChunkName: "component---src-pages-programmes-js" */),
  "component---src-pages-understanding-hk-js": () => import("./../../../src/pages/understanding-hk.js" /* webpackChunkName: "component---src-pages-understanding-hk-js" */),
  "component---src-pages-wma-community-js": () => import("./../../../src/pages/wma-community.js" /* webpackChunkName: "component---src-pages-wma-community-js" */),
  "component---src-pages-wma-space-js": () => import("./../../../src/pages/wma-space.js" /* webpackChunkName: "component---src-pages-wma-space-js" */),
  "component---src-pages-zh-404-js": () => import("./../../../src/pages/zh/404.js" /* webpackChunkName: "component---src-pages-zh-404-js" */),
  "component---src-pages-zh-about-js": () => import("./../../../src/pages/zh/about.js" /* webpackChunkName: "component---src-pages-zh-about-js" */),
  "component---src-pages-zh-commission-js": () => import("./../../../src/pages/zh/commission.js" /* webpackChunkName: "component---src-pages-zh-commission-js" */),
  "component---src-pages-zh-contact-js": () => import("./../../../src/pages/zh/contact.js" /* webpackChunkName: "component---src-pages-zh-contact-js" */),
  "component---src-pages-zh-current-exhibitions-js": () => import("./../../../src/pages/zh/current-exhibitions.js" /* webpackChunkName: "component---src-pages-zh-current-exhibitions-js" */),
  "component---src-pages-zh-events-js": () => import("./../../../src/pages/zh/events.js" /* webpackChunkName: "component---src-pages-zh-events-js" */),
  "component---src-pages-zh-index-js": () => import("./../../../src/pages/zh/index.js" /* webpackChunkName: "component---src-pages-zh-index-js" */),
  "component---src-pages-zh-legal-js": () => import("./../../../src/pages/zh/legal.js" /* webpackChunkName: "component---src-pages-zh-legal-js" */),
  "component---src-pages-zh-news-js": () => import("./../../../src/pages/zh/news.js" /* webpackChunkName: "component---src-pages-zh-news-js" */),
  "component---src-pages-zh-programmes-js": () => import("./../../../src/pages/zh/programmes.js" /* webpackChunkName: "component---src-pages-zh-programmes-js" */),
  "component---src-pages-zh-understanding-hk-js": () => import("./../../../src/pages/zh/understanding-hk.js" /* webpackChunkName: "component---src-pages-zh-understanding-hk-js" */),
  "component---src-pages-zh-wma-community-js": () => import("./../../../src/pages/zh/wma-community.js" /* webpackChunkName: "component---src-pages-zh-wma-community-js" */),
  "component---src-pages-zh-wma-space-js": () => import("./../../../src/pages/zh/wma-space.js" /* webpackChunkName: "component---src-pages-zh-wma-space-js" */)
}

