import { motion } from "framer-motion";
import React, { useState } from "react";
import { useInterval } from "../../hooks/TimerHooks";
import logoData from "./data";
import { colours } from "../../assets/theme";
import { deleteItemByIndex } from "../../helpers/utils";

const theme = { foreground: colours.ui.black, background: colours.ui.white };

const LoaderLogo = ({ height = 135, onComplete, ...rest }) => {
  const [loadedParts, setLoadedParts] = useState([]);
  const [logoParts, setLogoParts] = useState(logoData);
  const [partsLoaded, setPartsLoaded] = useState(false);

  useInterval(() => {
    if (logoParts.length) {
      const randomIndex = Math.floor(Math.random() * logoParts.length);
      setLoadedParts((prevState) => [...prevState, logoParts[randomIndex]]);
      setLogoParts(deleteItemByIndex(logoParts, randomIndex));
    }
  }, 50);

  const handleAnimationComplete = () => {
    if (logoParts.length === 0) {
      setPartsLoaded(true);
      onComplete();
    }
  };

  return (
    <motion.svg
      initial={{ height, overflow: "visible" }}
      animate={{
        height,
        transition: { type: "spring", bounce: 1, duration: 0.2 },
      }}
      viewBox="0 0 674 177"
      {...rest}
    >
      {loadedParts.map((path) => (
        <motion.path
          key={path}
          d={path}
          animate={{
            fill: partsLoaded ? theme?.foreground : theme?.background,
          }}
          transition={{
            duration: partsLoaded ? 1.5 : 0.2,
          }}
          stroke={theme?.foreground}
          strokeWidth={1}
          onAnimationComplete={handleAnimationComplete}
        />
      ))}
    </motion.svg>
  );
};

export default LoaderLogo;
