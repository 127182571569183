import React, { useEffect, useState } from 'react'

import './src/assets/styles/index.css'
import { useWindowSize } from 'react-use'
import { useTimeout } from './src/hooks/TimerHooks'
import { useDeviceStore, useUiStore } from './src/store'
import Loader from './src/components/Loader'

const windowGlobal = typeof window !== 'undefined' && window

const AppWrapper = ({ children }) => {
  const [animationComplete, setAnimationComplete] = useState(false)
  const { loaderFinished, setLoaderFinished } = useUiStore((store) => ({
    loaderFinished: store.loaderFinished,
    setLoaderFinished: store.setLoaderFinished,
  }))
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  useEffect(() => {
    const pageAccessedByReload =
      (windowGlobal.performance.navigation && windowGlobal.performance.navigation.type === 1) ||
      windowGlobal.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload')

    if (pageAccessedByReload) {
      setLoaderFinished(true)
    }
  }, [])

  useEffect(() => {
    setWindowWidth(windowWidth)
    setWindowHeight(windowHeight)
  }, [windowWidth, windowHeight])

  const setWindowWidth = useDeviceStore((store) => store.setWindowWidth)
  const setWindowHeight = useDeviceStore((store) => store.setWindowHeight)

  useTimeout(() => setLoaderFinished(true), animationComplete ? 1000 : null)
  const handleComplete = () => setAnimationComplete(true)

  return loaderFinished && windowWidth && windowHeight ? (
    children
  ) : (
    <Loader onComplete={handleComplete}>{children}</Loader>
  )
}

export const wrapRootElement = ({ element, props }) => <AppWrapper>{element}</AppWrapper>

export const shouldUpdateScroll = ({ routerProps }) => {
  const disableScrollUpdate = routerProps?.location?.state?.disableScrollUpdate

  return !disableScrollUpdate
}
